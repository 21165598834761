import { useEffect } from 'react';
import { useAppSelector } from '@/hooks/hooks';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import useWindowSize from '@/hooks/useWindowSize';
import { open } from '@/redux/rootReducer';
import {
  setFormRegistration,
  setFormRegistrationRestoran,
} from '@/redux/formsReducer';
import { redirect } from '@/helper/functions';
import { useRouter } from 'next/router';

import CustomLink from '@/components/UI/link';
import ImageComponent from '@/components/UI/image';
import SelectLanguage from '@/components/selectLanguage';
import Button from '@/components/UI/button';

import icon from '@/assets/icons/profile-circle.svg';
import logo from '@/assets/icons/Logo.svg';
import burgerMenu from '@/assets/icons/BurgerMenu.svg';

import style from './header.module.scss';

type Props = {
  register?: boolean;
};

const Header: React.FC<Props> = ({ register }) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const registration = useAppSelector(
    (state) => state.formState.formRegistration
  );
  const registrationRestoran = useAppSelector(
    (state) => state.formState.formRegistrationRestoran
  );
  const { windowWidth } = useWindowSize();
  const { t } = useTranslation();

  useEffect(() => {
    const currentRestoranData = sessionStorage.getItem('registorRestoran');
    const currentRegistration = sessionStorage.getItem('registration');

    if (
      currentRestoranData &&
      Object.values(registrationRestoran).every((el) => !el.length)
    ) {
      dispatch(setFormRegistrationRestoran(JSON.parse(currentRestoranData)));
    }

    if (
      currentRegistration &&
      Object.values(registration).every((el) => !el.length)
    ) {
      dispatch(setFormRegistration(JSON.parse(currentRegistration)));
    }
  }, [dispatch, registration, registrationRestoran]);

  const openLoginPopup = () => {
    dispatch(open());
  };

  const renderBurgerMenu = () =>
    windowWidth <= 768 &&
    !register && (
      <Button
        text=''
        func={openLoginPopup}
        otherStyle={style.wrapper_burgerMenu}
      >
        <ImageComponent
          image={burgerMenu}
          alt='burgerMenu'
          otherStyle={style.burgerMenu}
        />
      </Button>
    );

  const renderLanguages = windowWidth <= 768 && (
    <SelectLanguage languages={['UA', 'EN', 'RO']} />
  );

  const renderLoginButton = () =>
    !register && (
      <Button
        text={t('header.enter')}
        otherStyle={style.enter}
        func={openLoginPopup}
      >
        <ImageComponent image={icon} alt={'logo'} />
      </Button>
    );

  const renderRegisterButton = () =>
    !register && (
      <CustomLink
        href='/registration'
        target={false}
        style={style.plug}
        text='header.add'
      />
    );

  return (
    <header className={style.header}>
      <div className={style.wrapper}>
        {renderBurgerMenu()}
        <div className={style.header_wrapper}>
          {renderLoginButton()}
          <SelectLanguage languages={['UA', 'EN', 'RO']} />
        </div>
        <div
          className={classNames(style.wrapper_logo, {
            [style.registration]: register,
          })}
          onClick={() => redirect(router, '')}
        >
          <ImageComponent image={logo} alt='logo' otherStyle={style.logo} />
        </div>
        {renderLanguages}
        {renderRegisterButton()}
      </div>
    </header>
  );
};

export default Header;
